export const LANGUAGES = ['en', 'fr'];

export const PAGES = {
  ACCUEIL: 'accueil',
  CABINET: 'cabinet',
  COMPETENCES: 'competences',
  COMPETENCES_ASSURANCES: 'competences_assurances',
  COMPETENCES_CONSTRUCTION: 'competences_construction',
  COMPETENCES_GARDE_ENFANTS: 'competences_garde_enfants',
  COMPETENCES_IMMOBILIER: 'competences_immobilier',
  COMPETENCES_MEDIATION: 'competences_mediation',
  COMPETENCES_RESPONSABILITE: 'competences_responsabilite',
  COMPETENCES_SEPARATION_DIVORCE: 'competences_separation_divorce',
  CONTACT: 'CONTACT',
  EQUIPE: 'EQUIPE',
  MERCI: 'MERCI',
};

export const PAGES_URL = {
  en: {
    accueil: '/en/home',
    cabinet: '/en/firm',
    competences: '/en/expertise',
    competences_assurances: '/en/expertise/insurance',
    competences_construction: '/en/expertise/construction',
    competences_garde_enfants: '/en/expertise/child-custody',
    competences_immobilier: '/en/expertise/real-estate',
    competences_mediation: '/en/expertise/mediation',
    competences_responsabilite: '/en/expertise/liability',
    competences_separation_divorce: '/en/expertise/separation-divorce',
    CONTACT: '/en/contact',
    EQUIPE: '/en/team',
    MERCI: '/en/thank-you',
  },
  fr: {
    accueil: '/',
    cabinet: '/cabinet',
    competences: '/competences',
    competences_assurances: '/competences/assurances',
    competences_construction: '/competences/construction',
    competences_garde_enfants: '/competences/garde-enfants',
    competences_immobilier: '/competences/immobilier',
    competences_mediation: '/competences/mediation',
    competences_responsabilite: '/competences/responsabilite',
    competences_separation_divorce: '/competences/separation-divorce',
    CONTACT: '/contact',
    EQUIPE: '/equipe',
    MERCI: '/merci',
  },
};

export function getYearsSinceOfficeBirth() {
  const timeUntilOfficeBirth = new Date() - new Date('1975-11-11');
  const yearInMs = 365.25 * 24 * 3600 * 1000; // days * hours * seconds * ms
  const years = Math.floor(timeUntilOfficeBirth / yearInMs);

  return years;
}

export function getYearsSinceOfficeBirthFactorFive() {
  const years = getYearsSinceOfficeBirth();
  return years - (years % 5);
}
