/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            en {
              author
              description
              locale
              image
              title
            }
            fr {
              author
              description
              locale
              image
              title
            }
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata[lang].description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata[lang].title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: site.siteMetadata[lang].image,
        },
        {
          property: 'og:locale',
          content: site.siteMetadata[lang].locale,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata[lang].author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:url',
          content: 'https://lemireavocat.com',
        },
        {
          name: 'twitter:image',
          content: site.siteMetadata[lang].image,
        },
        {
          name: 'viewport',
          content: 'width=device-width',
        },
      ]}
      link={[
        {
          rel: 'apple-touch-icon',
          href: '/img/apple-touch-icon.png',
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: 'fr',
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SEO;
