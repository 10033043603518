import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const EnTete = ({ translationUrls }) => (
  <header id="header">
    <Link id="language" to={translationUrls.en}>
      English
    </Link>
    <div id="logo" itemScope itemType="https://schema.org/LocalBusiness">
      <Link to="/">
        <img
          itemProp="logo"
          src="/img/logo.png"
          alt="logo"
          height="75"
          width="191"
          title="Lemire Lemire avocats s.e.n.c.r.l."
        />
      </Link>
      <span id="phone2" itemProp="telephone">
        <a href="tel:+14509661055">450-966-1055</a>
      </span>
    </div>

    {/* <!-- La division NAV gère le menu de navigation du haut --> */}
    <nav>
      <ul id="listeNav">
        <li>
          <Link className="effect1" to="/">
            Accueil
          </Link>
        </li>
        <li>
          <Link className="effect1" to="/equipe">
            Équipe
          </Link>
        </li>
        <li>
          <Link className="effect1" to="/competences">
            Compétences
          </Link>
        </li>
        <li>
          <Link className="effect1" to="/cabinet">
            Cabinet
          </Link>
        </li>
        <li>
          <Link className="effect1" to="/contact">
            Contact
          </Link>
        </li>
        <li>
          <div id="icones" className="effect1">
            <a
              id="fb"
              href="https://www.facebook.com/Lemire-Lemire-avocats-sencrl-558664590836838/?ref=page_internal"
              target="_blank"
              rel="noopener noreferrer"
              title="Lemire Lemire avocats s.e.n.c.r.l. sur Facebook"
            >
              <span>Facebook</span>
            </a>
            <a
              href="https://www.linkedin.com/company/lemire-lemire-avocats-s-e-n-c-/about/"
              target="_blank"
              rel="noopener noreferrer"
              title="Lemire Lemire avocats s.e.n.c.r.l. sur LinkedIn"
              id="linkedin"
            >
              <span>LinkedIn</span>
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </header>
);

EnTete.propTypes = {
  translationUrls: PropTypes.shape({
    en: PropTypes.string.isRequired,
  }).isRequired,
};

export default EnTete;
