/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import { LANGUAGES, PAGES, PAGES_URL } from '../utils/utils';
import FooterComp from './en/footer';
import HeaderComp from './en/header';
import BasDePage from './fr/basdepage';
import EnTete from './fr/entete';
import './layout.css';
import './normalize.css';

const Layout = ({ children, lang, page }) => {
  const Header = lang === 'fr' ? EnTete : HeaderComp;
  const Footer = lang === 'fr' ? BasDePage : FooterComp;

  const translationUrls = LANGUAGES.reduce((acc, language) => {
    if (language === lang) {
      return acc;
    }

    return {
      ...acc,
      [language]: PAGES_URL[language][page],
    };
  }, {});

  return (
    <div id="contenant" className="effect8">
      <Header translationUrls={translationUrls} />
      <main>
        <div id="contenu">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  lang: 'fr',
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.oneOf(LANGUAGES),
  page: PropTypes.oneOf(Object.values(PAGES)).isRequired,
};

export default Layout;
