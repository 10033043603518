import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import { runSideEffects } from '../../scripts/side-effects';

const Footer = () => {
  const [isGlobalScriptInitiated, setIsGlobalScriptInitiated] = useState(false);

  useEffect(() => {
    if (!isGlobalScriptInitiated) {
      runSideEffects();
      setIsGlobalScriptInitiated(true);
    }
  });

  return (
    <footer id="footer" className="clearfix">
      <div id="colonne1">
        <h3>Site Map</h3>
        <ul>
          <li>
            <Link to="/en/home">Home</Link>
          </li>
          <li>
            <Link to="/en/team">Team</Link>
          </li>
          <li>
            <Link to="/en/expertise">Expertise</Link>
          </li>
          <li>
            <Link to="/en/firm">Firm</Link>
          </li>
          <li>
            <Link to="/en/contact">Contact</Link>
          </li>
        </ul>
      </div>

      <div id="colonne2">
        <div itemScope itemType="https://schema.org/LocalBusiness">
          <h3 itemProp="name">Lemire Lemire avocats LLP</h3>
          <ul>
            <li>
              <span itemProp="address">
                <a
                  itemProp="map"
                  href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=fr&amp;geocode=&amp;q=Lemire+Lemire+avocats+s.e.n.c.+Mascouche&amp;sll=45.751055,-73.61063&amp;sspn=0.034976,0.065746&amp;ie=UTF8&amp;hq=lemire+lemire+avocats&amp;hnear=Mascouche,+Qu%C3%A9bec&amp;t=m&amp;cid=11202491731402244976&amp;ll=45.755966,-73.613763&amp;spn=0.014971,0.032573&amp;z=15&amp;iwloc=A"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2724, Chemin Sainte-Marie
                  <br />
                  Mascouche (Québec) J7K 1N1
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div id="colonne3">
        <ul>
          <h3>Contact</h3>
          <li>
            tel:{' '}
            <span id="phone" itemProp="telephone">
              <a href="tel:+14509661055">450.966.1055</a>
            </span>
          </li>
          <li>
            Fax: <span itemProp="faxNumber">450.966.0193</span>
          </li>
          <li>
            <span itemProp="email">
              <a href="mailto:info@lemireavocat.com">info@lemireavocat.com</a>
            </span>
          </li>
          <br />
          <br />© {new Date().getFullYear()} Lemire Lemire avocats LLP
        </ul>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
