export function runSideEffects() {
  highlightActivePage();
  socialButtons();
  boutonsHover();

  // script pour que les icones réagissent au mouse-over
  function socialButtons() {
    $('#fb').hover(
      function () {
        $(this).attr('src', 'img/facebook.png');
      },
      function () {
        $(this).attr('src', 'img/facebook_h.png');
      },
    );

    $('#linkedin').hover(
      function () {
        $(this).attr('src', 'img/linkedin.png');
      },
      function () {
        $(this).attr('src', 'img/linkedin_h.png');
      },
    );
  }

  function boutonsHover() {
    let couleurInit;
    $('.bouton').hover(
      function () {
        couleurInit = $(this).css('background-color');
        $(this).css({ 'background-color': '#ddd' });
      },
      function () {
        $(this).css('background-color', couleurInit);
      },
    );
  }

  /**
   * Applique le style 'currentPage' sur l'onglet actif
   */
  function highlightActivePage() {
    const menuNav = window.document.getElementById('listeNav');
    const nav = menuNav.getElementsByTagName('a');

    if (window.location.pathname === '/') {
      nav[0].id = 'currentPage';
    } else {
      for (let i = 0; i < nav.length; i++) {
        if (
          nav[i].pathname !== '/' &&
          window.location.pathname.includes(nav[i].pathname)
        ) {
          nav[i].id = 'currentPage';
        }
      }
    }
  }
}
